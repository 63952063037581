(function() {
    'use strict';

    angular.module("aerosApp").controller("ContactsCtrl", OrganizationContactsController);

    OrganizationContactsController.$inject = ["$scope", "aerosApi", 'Notification', 'orgContactModal', 'contactTypes',
        'tinyImg', '$uibModal', 'uploadLogoModal', 'PageService', "instructions", "routesConstant"];

    function OrganizationContactsController($scope, aerosApi, Notification, orgContactModal, contactTypes, tinyImg,
                                            $uibModal, uploadLogoModal, PageService, instructions, routesConstant) {
        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;
            if (!$scope.allow['viewContacts']) return $scope.reject();
            $scope.tinyImg = tinyImg;
            function loadContacts () {
                aerosApi.getAllContacts($scope.organization.id).then(function (rsp) {
                    $scope.contacts = _.sortBy(rsp.data.contacts, function (contact) {
                        return contact.defaultContact === false;
                    });
                    $scope.defaultContacts = _.groupBy( _.filter(rsp.data.contacts, {"defaultContact": true}), "contactType");
                });
            }
            $scope.loadContacts = loadContacts;
            PageService.setTitle('Organization Contacts');
            loadContacts();

            $scope.showContactForm = function(obj, isViewOnly) {
                orgContactModal.open(contactTypes, obj, $scope.allow['setDefaultContacts'], isViewOnly).then(function (data) {
                    if (isViewOnly) return;
                    var contact = data.contact;
                    if (contact.defaultContact &&
                        $scope.defaultContacts[contact.contactType] &&
                        $scope.defaultContacts[contact.contactType][0].id !== contact.id
                    ) {
                        if ( confirm("The Current default " + contact.contactType + " is " + $scope.defaultContacts[contact.contactType][0].companyName + ". Are you sure you want to change to " + contact.companyName + "?") ) {
                            var oldContact = $scope.defaultContacts[contact.contactType][0];
                            oldContact.defaultContact = false;
                            aerosApi.editContact ($scope.organization.id, oldContact.id, oldContact);
                        } else {
                            contact.defaultContact = false;
                            return $scope.showContactForm(contact);
                        }
                    }
                    if (contact.id) {
                        aerosApi.editContact ($scope.organization.id, contact.id, contact).then(function (rsp) {
                            loadContacts();
                            if (data.uploadLogo) {
                                $scope.uploadLogo(rsp.data.contact);
                            }
                        });
                    } else {
                        aerosApi.createContact($scope.organization.id, contact).then(function (rsp) {
                            if (rsp.status === 400) {
                                Notification.error("Organization Contact" + rsp.data.detail);
                            } else {
                                loadContacts();
                                if (data.uploadLogo) {
                                    $scope.uploadLogo(rsp.data.contact);
                                }
                            }
                        }, function(rsp) {
                            if (rsp.status === 400) {
                                Notification.error("Organization Contact " + rsp.data.detail);
                            } else {
                                Notification.error("Error creating Organization Contact.");
                            }
                        });
                    }
                });
            };
            $scope.editContact = function (obj) {
                if (!$scope.allow['createContacts']) return $scope.reject();
                $scope.showContactForm(obj);
            };
            $scope.createNewContact = function () {
                if (!$scope.allow['createContacts']) return $scope.reject();
                $scope.showContactForm();
            };
            $scope.viewContact = function (obj) {
                var isViewOnly = true;
                $scope.showContactForm(obj, isViewOnly);
            };
            $scope.deleteContact = function(contact) {
                if (!$scope.allow['deleteContacts']) return $scope.reject();
                $uibModal.open({
                    size: "md",
                    templateUrl: 'confirmDeleteModal.html',
                    controller: ["$scope", "contact", function($scope, contact){
                        $scope.contact = contact;
                    }],
                    resolve: {
                        contact: function () {
                            return contact;
                        }
                    }
                }).result
                    .then(function () {
                        if (!$scope.allow['deleteContacts']) return $scope.reject();
                        return aerosApi.updateContactStatus ($scope.organization.id, contact, "Deleted");
                    })
                    .then(function() {
                        return loadContacts();
                    });
            };
        });
        $scope.uploadLogo = function (contact) {
            uploadLogoModal.open(contact).then(function (file) {
                if (file === "delete") {
                    aerosApi.deleteContactLogo($scope.organization.id, contact.id).then(function(rtn) {
                        Notification.success("Deleted logo for " + contact.companyName);
                        $scope.loadContacts();
                    });
                } else {
                    aerosApi.setContactLogo($scope.organization.id, contact.id, file).then(function(rtn) {
                        Notification.success("Uploaded logo for " + contact.companyName);
                        $scope.loadContacts();
                    }, function(result) {
                        Notification.error("Upload logo error: " + result.data.errors);
                    });
                }
            });
        };

        function findInstructionByAction(action) {
            return instructions[routesConstant.CUSTOMER.ORGANIZATION_CONTACTS.stateName]
                .actions.find(function (element) {
                    return element.action && element.action.toLowerCase() === action.toLowerCase();
                });
        }

        (function setActions() {
            $scope.actions = {
                "New Contact": {
                    label: function label(contact) {
                        return findInstructionByAction("New Contact").instructions;
                    },
                    icon: function icon(contact) {
                        return 'fa-' + findInstructionByAction("New Contact").icon;
                    },
                    if: function (contact) {
                        return $scope.allow.createContacts;
                    },
                    disabled: function (contact) {
                        return false;
                    },
                    action: function (contact) {
                        return $scope.createNewContact();
                    },
                    placement: function(contact) {
                        return 'left';
                    }
                }
            }

            $scope.orgContactActions = [{
                label: function label(contact) {
                    return findInstructionByAction("Upload Contact Logo").instructions;
                },
                icon: function icon(contact) {
                    return 'fa-' + findInstructionByAction("Upload Contact Logo").icon;
                },
                if: function (contact) {
                    return $scope.allow.createContacts;
                },
                disabled: function (contact) {
                    return false;
                },
                action: function (contact) {
                    return $scope.uploadLogo(contact);
                }
            }, {
                label: function label(contact) {
                    return findInstructionByAction("Edit Contact").instructions;
                },
                icon: function icon(contact) {
                    return 'fa-' + findInstructionByAction("Edit Contact").icon;
                },
                if: function (contact) {
                    return $scope.allow.createContacts;
                },
                disabled: function (contact) {
                    return false;
                },
                action: function (contact) {
                    return $scope.editContact(contact);
                }
            }, {
                label: function label(contact) {
                    return findInstructionByAction("Delete Contact").instructions;
                },
                icon: function icon(contact) {
                    return 'fa-' + findInstructionByAction("Delete Contact").icon;
                },
                if: function (contact) {
                    return $scope.allow.deleteContacts;
                },
                disabled: function (contact) {
                    return false;
                },
                action: function (contact) {
                    return $scope.deleteContact(contact);
                },
                placement: function(contact) {
                    return 'left';
                }
            }, {
                label: function label(contact) {
                    return findInstructionByAction("Info").instructions;
                },
                icon: function icon(contact) {
                    return 'fa-' + findInstructionByAction("Info").icon;
                },
                if: function (contact) {
                    return true;
                },
                disabled: function (contact) {
                    return false;
                },
                action: function (contact) {
                    return $scope.viewContact(contact);
                },
                placement: function(contact) {
                    return 'left';
                }
            }];

        })();

    }
})();
